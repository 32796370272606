.flex {
  display: flex;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-b-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-b-s {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-s-c {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
