.p-8 {
  padding: 8px;
}

.p-16 {
  padding: 16px;
}
.p-24 {
  padding: 24px;
}
.p-48 {
  padding: 48px;
}
.p0 {
  padding: 0;
}
.pb-16 {
  padding-bottom: 16px;
}
.pb-32 {
  padding-bottom: 2rem;
}
.pb-40 {
  padding-bottom: 40px;
}
.pt-16 {
  padding-top: 16px;
}
.pt-40 {
  padding-top: 40px;
}
.p-12-16 {
  padding: 12px 16px;
}

.p-0-0-0-16 {
  padding: 0 0 0 16px;
}
