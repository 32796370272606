.mb-2 {
  margin-bottom: 2px;
}

.mb-4 {
  margin-bottom: 4px;
}
.mb-6 {
  margin-bottom: 6px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-12 {
  margin-bottom: 0.75rem;
}
.mb-14 {
  margin-bottom: 0.875rem;
}
.mb-16 {
  margin-bottom: 1rem;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-22 {
  margin-bottom: 1.375rem;
}
.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 2rem;
}
.mb-60 {
  margin-bottom: 3.75rem;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-4 {
  margin-left: 0.25rem;
}

.ml-6 {
  margin-left: 6px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-13 {
  margin-left: 13px;
}

.ml-14 {
  margin-left: 14px;
}

.ml-16 {
  margin-left: 16px;
}
.ml-23 {
  margin-left: 23px;
}
.ml-36 {
  margin-left: 36px;
}

.ml-38 {
  margin-left: 38px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-8 {
  margin-right: 0.5rem;
}

.mr-12 {
  margin-right: 12px;
}
.mr-16 {
  margin-right: 16px;
}
.mx-20 {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}

.mt-2 {
  margin-top: 2px;
}

.mt-4 {
  margin-top: 4px;
}

.m-24-0-10 {
  margin: 24px 0 10px;
}
