
.documentation {
    background-color: #E5E5E5;
}

.documentation h3{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 1.5em;
    color: #2F363D;
}
.documentation-body-side {
    height: 100vh;
    padding: 24px 20px 24px 20px;
}

.documentation-body-main {
    height: 100vh;
    padding: 24px 32px 24px 20px;
}
