.text-align-right {
  text-align: right;
}
.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

@media only screen and (max-width: 1280px) {
  .text-align-1280-center {
    text-align: center;
  }
}
