body {
  margin: 0;
  font-family: inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* kepler global styles */
.side-bar__inner {
  overflow-y: scroll;
  padding-bottom: 32px;
}
.kg-range-slider__input {
  background: white !important;
  border: 1px solid #e1e4e8 !important;
  border-radius: 2px !important;
  color: #24292e !important;
  font-family: inter !important;
  font-size: 1em !important;
  font-weight: 400 !important;
}
/* antd popover */
.filter-modal .ant-popover-arrow {
  display: none;
}
