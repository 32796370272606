.text1 {
  color: #24292e;
  font-size: 1em;
  font-family: inter;
}

.text2 {
  color: #444d56;
  font-size: 1em;
  font-family: inter;
}

.text3 {
  color: #24292e;
  font-size: 1.3em;
  font-family: inter;
}

.text4 {
  color: #24292e;
  font-size: 1.3em;
  font-family: inter;
  line-height: 20px;
}

.text5 {
  color: #24292e;
  font-size: 1em;
  font-family: inter;
  line-height: 15.6px;
}

.text6 {
  color: #24292e;
  font-size: 1em;
  font-family: inter;
  line-height: 15px;
  font-weight: 500;
}

.text7 {
  color: #586069;
  font-size: 1em;
  font-family: inter;
  line-height: 15.6px;
}

.text8 {
  color: #262626;
  font-size: 1.1em;
  font-family: inter;
  line-height: 19.6px;
}

.text9 {
  color: #595959;
  font-size: 1em;
  font-family: inter;
  line-height: 16.8px;
}

.text10 {
  color: #24292e;
  font-size: 1.1em;
  font-family: inter;
  line-height: 22px;
}

.text11 {
  color: #24292e;
  font-size: 0.8em;
  font-family: inter;
  line-height: 18.2px;
}

.text12 {
  color: #24292e;
  font-size: 1em;
  font-family: inter;
  line-height: 20px;
}

.text13 {
  color: #24292e;
  font-size: 1em;
  font-family: inter;
  line-height: 15.6px;
  font-weight: 600;
}

.text14 {
  color: #6a737d;
  font-size: 1em;
  font-family: inter;
  line-height: 1.2em;
  font-weight: 400;
}

.text15 {
  font-size: 24px;
  font-weight: 600;
  color: #24292e;
  line-height: 30px;
}

.text16 {
  font-size: 14px;
  color: #24292e;
  line-height: 17.5px;
}

.text17 {
  font-size: 12px;
  color: #24292e;
  line-height: 15px;
  font-weight: 600;
}

.text18 {
  font-size: 16px;
  font-weight: 500;
  color: #24292e;
  line-height: 22px;
}

.text19 {
  font-size: 16px;
  font-weight: 600;
  color: #24292e;
  line-height: 20px;
}

.text20 {
  font-size: 14px;
  color: #000000;
  line-height: 17.5px;
  font-weight: 600;
}

.text21 {
  font-size: 14px;
  color: #000000;
  line-height: 17.5px;
}

.text22 {
  font-size: 12px;
  color: #000000;
  line-height: 15px;
}
.text23 {
  color: #444d56;
  font-size: 12px;
  line-height: 20px;
}

.text24 {
  color: #262626;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.text25 {
  color: #444d56;
  font-size: 14px;
  line-height: 19.6px;
}

.text26 {
  color: #2579dd;
  font-size: 14px;
  line-height: 22px;
}

.text27 {
  color: #24292e;
  font-size: 20px;
  line-height: 26px;
}

.text28 {
  color: #262626;
  font-size: 0.875rem;
  line-height: 19.6px;
  font-weight: 600;
}
