.fw-bold {
  font-weight: bold;
}

.fw-light {
  font-weight: 300;
}
.fw-medium {
  font-weight: 500;
}
.fw-semibold {
  font-weight: 600;
}
.fw-extrabold {
  font-weight: 800;
}
